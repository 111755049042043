import React, { useState, useEffect } from "react";
import * as Styled from "./style";

// Utility function to check if a date is within the last 7 days
const isWithinLastWeek = (date) => {
  const currentDate = new Date();
  const notificationDate = new Date(date);
  const diffInDays = (currentDate - notificationDate) / (1000 * 60 * 60 * 24);
  return diffInDays <= 7;
};

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);

  // Function to initialize WebSocket connection
  useEffect(() => {
    const ws = new WebSocket("ws://localhost:4000"); // Replace with your server WebSocket URL
    ws.onopen = () => {
      console.log("Connected to WebSocket server");
    };
    
    ws.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      console.log("New notification received:", newNotification);

      // Add new notification to the state
      setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
    };

    setSocket(ws);

    return () => {
      if (ws) {
        ws.close(); // Close WebSocket connection on cleanup
      }
    };
  }, []);

  // Dummy data with dates (you might replace this with your actual data)
  const dummyNotifications = [
    {
      id: 1,
      avatar: "https://via.placeholder.com/40",
      message: "@Raymond Golphs has sent you a friend request",
      date: "2024-11-08",
    },
    {
      id: 2,
      avatar: "https://via.placeholder.com/40",
      message: "You and @Raymond Golphs are now friends",
      date: "2024-11-01", // older than 7 days
    },
    {
      id: 3,
      avatar: "https://via.placeholder.com/40",
      message: "@Jane Doe liked your photo",
      date: "2024-11-05",
    },
  ];

  // Filter notifications based on the date
  const newNotifications = notifications.filter((notif) => isWithinLastWeek(notif.date));
  const lastWeekNotifications = notifications.filter((notif) => !isWithinLastWeek(notif.date));

  return (
    <div style={{ display: `flex`, flexDirection: `column`, width: `${100}%` }}>
      <Styled.NotificationTitle>Notifications</Styled.NotificationTitle>
      <Styled.NotificationContainer>
        {notifications.length > 0 ? (
          <>
            {newNotifications.length > 0 && (
              <Styled.NotificationSection>
                <Styled.NotificatinSectionTitle>New</Styled.NotificatinSectionTitle>
                {newNotifications.map((notif) => (
                  <Styled.Notification key={notif.id}>
                    <Styled.NotifcationAvatar src={notif.avatar} alt={notif.message} />
                    <Styled.NotificationMessage>
                      <strong>{notif.message}</strong>
                    </Styled.NotificationMessage>
                  </Styled.Notification>
                ))}
              </Styled.NotificationSection>
            )}

            {lastWeekNotifications.length > 0 && (
              <Styled.NotificationSection>
                <Styled.NotificatinSectionTitle>Last Week</Styled.NotificatinSectionTitle>
                {lastWeekNotifications.map((notif) => (
                  <Styled.Notification key={notif.id}>
                    <Styled.NotifcationAvatar src={notif.avatar} alt={notif.message} />
                    <Styled.NotificationMessage>
                      <strong>{notif.message}</strong>
                    </Styled.NotificationMessage>
                  </Styled.Notification>
                ))}
              </Styled.NotificationSection>
            )}
          </>
        ) : (
          <Styled.NotificationMessage>
            Currently, you don't have new notifications
          </Styled.NotificationMessage>
        )}
      </Styled.NotificationContainer>
    </div>
  );
};

export default Notification;
